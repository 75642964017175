// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("jquery");
require("channels");
require("summernote");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";
import "../stylesheets/application";
import $ from "jquery";
import "summernote/dist/summernote-bs4";
import "summernote/dist/summernote-bs4.css";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $("#email_body").summernote({
    airMode: false,
    height: 350,
  });

  $("#taskify").on("click", () => {
    var body = $("#email_body").summernote("code");
    var data = {
      sender: "test@example.com",
      recipients: { to: ["recipient@example.com"] },
      email_id: "email_id",
      thread_id: "thread_id",
      body: body,
      subject: "Subject",
    };
    $.ajax({
      url: `/tasks`,
      type: "POST",
      data: data,
      success: (result) => {
        $("#email_results").html(result);
      },
      error: (result) => {
        $("#email_results").html(result);
      },
    });
  });
});
